export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-4.jpg',
        userName: 'Bruno',
        email: 'contato@outbox.inf.br',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin',
    },
]
