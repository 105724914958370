const appConfig = {
    apiPrefix: process.env.REACT_APP_ENDERECO_WS,
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/acessar',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
